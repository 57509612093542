<template lang="pug">
  div
    Header
    .w-100.text-center.mt-4
      .col-12.contain.color-colmedica-gray
        p(style="font-size: 1.3rem; text-align: center;") Estamos verificando interacciones anteriores...
</template>

<script>
import { mapActions, mapState } from "vuex";
import LS from "@/helpers/localStorage";
import { b64_to_utf8, try_json, utf8_to_b64 } from "@/utils/decoder";
import { getOneUseToken } from "@/helpers/Meet";

import Header from "./components/Header";

export default {
  name: "TurnChecker",

  components: {
    Header
  },

  created() {
    this.checkTurn();
  },

  computed: {
    ...mapState({
      turn: state => state.turn
    })
  },

  methods: {
    ...mapActions({
      getTurnRequest: "getTurnRequest",
      goToView: "autoservice/goToView",
      putLog: "log/put"
    }),

    async checkTurn() {
      let json;
      try {
        json = try_json(b64_to_utf8(LS.getItem("rawB64")));
        json.to = await getOneUseToken();
        this.putLog({
          name: "TurnChecker. Validación de datos preliminares de Turno"
        });
        if (this.turn && json?.nu) {
          const result = await this.getTurnRequest(this.turn.code);
          this.putLog({ name: "TurnChecker. Solicitud del turno previo" });
          if (
            result.status === "WAITING_TO_BE_CALLED" ||
            result.status === "ANNOUNCED"
          ) {
            this.putLog({
              name:
                "TurnChecker. El turno está listo para atención, se redirecciona a la sección de turnos"
            });
            this.$router.push({
              name: "Decoder",
              query: { pr: utf8_to_b64(JSON.stringify(json)) }
            });
            return;
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.putLog({
          name: "TurnChecker. Error no bloqueante en verificación del turno.",
          checked: false,
          message: error
        });
      }
      this.putLog({
        name:
          "TurnChecker. No hay turno válido. Pasa a evaluación de compañía y Branch"
      });
      this.goToView("BranchValidation");
    }
  }
};
</script>
