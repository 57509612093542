<template lang="pug">
div
  Header
  .col-12.contain.color-colmedica-gray.mt-4
    h4.text-middle ¡Hemos recibido tu solicitud de autorización!
  ol.orderList
    li(v-for="list in orderList" :key="list")
      p {{ list }}
  p(style="margin: 30px auto; text-align: center;").color-colmedica-gray.text-sizing-1 Ya puedes cerrar esta ventana
</template>
<script>
import { mapState } from "vuex";
import Header from "./components/Header";
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";

export default {
  name: "OutOperationsFinish",
  components: {
    Header
  },
  computed: {
    ...mapState({
      branchInfo: state => state.autoservice.branchInfo,
      client: state => state.autoservice.client
    }),
    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + splited[1] : ""
      }`;
    },
    orderList() {
      return [
        "Nuestro equipo la revisará dentro de las próximas 12 horas hábiles.",
        `Podrás hacer seguimiento del proceso en nuestra App Colmedica.`,
        `
          Recibirás a ${this.email} un correo de confirmación
          del número de radicación y fecha de respusesta.
        `,
        `
          De ser necesario, nos contactaremos contigo
          para ampliar información de la solicitud.
        `
      ];
    }
  }
};
</script>
<style scoped>
.text-middle {
  margin: auto;
  width: 90%;
  margin-top: 35px;
  font-size: 17px;
  text-align: center;
  color: #00599d;
}
.orderList {
  max-width: 274px;
  margin: auto;
  margin-top: 50px;
}
</style>
