<template lang="pug">
  div(class="position-relative w-100 h-100", style="max-width: 100%")
    Header
    form.contain(@submit.prevent="sendData" autocomplete="off")
      p.text-middle.color-colmedica-gray.text-sizing-2 Selecciona el tipo de identificación
      b-form-select.mb-4(class="number-input" v-model="selected" :options="options" size="sm" required autocomplete="off")
      p.text-middle.color-colmedica-gray.text-sizing-2 Ingresa el número de identificación del usuario que requiere la autorización
      div
        input(class="number-input mt-4" autocomplete="off" v-model="number_id" :disabled="!isCapcha" :placeholder="_number" required)#cedula_number
        .key-board(v-if="!isCapcha")
          .row-numbers(v-for="uniqueList in listNumbers")
            button(
              type="button"
              v-for="(number) in uniqueList"
              :disabled="number === 'null'"
              :class="number!='null' ? 'number-button' : 'null-button'"
              :key="number"
              @click="number === 'null' ? null : (number == 'X' ? removeNumber() : addNumero(number))"
            ) {{ number }}
      .botton-actions
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goToView('Home')")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="submit" :disabled="charging || number_id === ''")
            div(v-if="!!!charging")
              span(class="text-sizing-12") Continuar
              Continue(size="24px")
            template(v-else)
              MiniSpinner
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import MiniSpinner from "../components/MiniSpinner";

export default {
  name: "Autenticate",
  components: {
    Header,
    Back,
    Continue,
    MiniSpinner
  },
  mounted() {
    this.captchaElement = document.querySelector(".grecaptcha-badge");
    if (this.captchaElement) this.captchaElement.classList.add("show");
  },
  beforeDestroy() {
    if (this.captchaElement) this.captchaElement.classList.remove("show");
  },
  data: () => ({
    captchaElement: null,
    debug: false,
    selected: "CC",
    number_id: "",
    listNumbers: [
      ["1", "2", "3"],
      ["4", "5", "6"],
      ["7", "8", "9"],
      ["null", "0", "X"]
    ],
    isCapcha: true
  }),
  destroyed() {
    this.selected = null;
    this.number_id = "";
  },
  computed: {
    ...mapState({
      options: state => state.autoservice.tipide,
      errors: state => state.autoservice.validationErrors,
      charging: state => state.autoservice.charging,
      env: state => state.env,
      branch_id: state => state.branchInfo,
    }),
    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },
    _number() {
      let number_id = this.number_id;
      if (number_id != "No. Identificación") {
        var convertedPhone = "******" + number_id.substring(6);
        if (number_id.length <= 6) {
          return number_id.replace(/\d/g, "*");
        }
        return convertedPhone;
      }
      return number_id;
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      captchaValidation: "autoservice/captchaValidation",
      updateB64Final: "autoservice/updateB64Final",
      authAndQueryColmedica: "autoservice/authAndQueryColmedica",
      demoValidation: "autoservice/demoValidation",
      authenticate: "virtualrow/authenticate",
    }),
    removeNumber() {
      if (this.number_id != "") {
        var data = this.number_id.substring(0, this.number_id.length - 1);
        if (data.length) this.number_id = data.toString();
        else this.number_id = "";
      }
    },
    addNumero(pay) {
      if (this.number_id == "") this.number_id = pay.toString();
      else this.number_id = `${this.number_id}${pay}`;
    },
    async sendData() {
      if (!this.selected || this.charging) return;
      let toSend = { ti: this.selected, nu: this.number_id };
      this.updateB64Final(toSend);
      document.getElementById("cedula_number")?.blur();
      if (this.demoMode) return this.demoValidation();
      console.log('pasa por aqui')
      await this.authenticate({
        NumeroIdentificacion: this.number_id,
        TipoIdentificacion: this.selected,
        CodigoOficina: this.branch_id.branch_id,
      });
      return this.authAndQueryColmedica(toSend);
    }
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

/*  Keyboard  */
.key-board {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row-numbers {
  margin: 4px auto;
  display: block;
  justify-content: center;
}
.number-input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}
.number-button {
  background-color: #0057a0;
  color: white;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}
.null-button {
  user-select: none;
  background-color: white;
  color: white;
  border: none;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}
/* Capcha config */
.capcha-contain {
  margin-top: 20px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}
</style>
