<template lang="pug">
  div(class="position-relative w-100 h-100", style="max-width: 100%")
    Header
    .contain(v-if="!isCorrect")
      p.text-middle.color-colmedica-gray.text-sizing-2 Confirma la información del usuario que requiere la autorización
      div(class="form-data")
        b-form-input(type="text" autocomplete="off" class="my-2 form-control-sm" :placeholder="name" :disabled="true")
        b-form-input(type="text" autocomplete="off" class="my-2 form-control-sm" :placeholder="email" :disabled="true")
        b-form-input(type="text" autocomplete="off" class="my-2 form-control-sm" :placeholder="phone" :disabled="true")
      p.text-middle.color-colmedica-gray.text-sizing-2 Tu correo electrónico y número celular se muestran parcialmente, por tu seguridad
      p.text-middle.color-colmedica-gray.text-sizing-2 Enviaremos la autorización al correo señalado. 
      .botton-actions
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goToView('Autenticate')")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
          b-button(variant="bluecolmedica" class="text-center ml-4" size="sm" type="button" @click="isCorrect = !isCorrect")
            span(class="text-sizing-12") Continuar
            Continue(size="24px")

    .contain(v-else)
      p.text-middle.color-colmedica-gray.text-sizing-2 La orden médica a autorizar te la entregó un médico de:
      div(class="actions-zone-2")
        b-button(variant="bluecolmedica" class="text-center btn-block button-origen" @click="loadData('Centro Médico')") Centro Médico Colmédica
        b-button(variant="bluecolmedica" class="text-center btn-block button-origen" @click="loadData('Profesional de la red', 'files')") Profesional de la red
        b-button(variant="bluecolmedica" class="text-center btn-block button-origen" @click="loadData('Clínica', 'files')") Clínica
      .botton-actions
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="isCorrect = !isCorrect")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold";
import { utf8_to_b64 } from "@/utils/decoder";
import asterisker from "@/helpers/asteriskHidden";
import { getOneUseToken } from "@/helpers/Meet";
import _get from "lodash/get";

export default {
  name: "VerificationData",
  components: {
    Header,
    Back,
    Continue
  },
  data: () => ({
    isCorrect: false
  }),
  mounted(){
    
    // tracking
    setTimeout(() => {
      let auth = !this.client ? ' autentitacion-no-cliente' : 'autenticacion-cliente';
      let trackingData  = {
        uuid: window.person.id,
        accion: 'customer-' + auth,
        debmedia_turn_code: '',
        url_origen: window.location.href,
        origen: _get(this.client, "fu") || 'ad',
        mensajes: ''
      }
      
      this.sendTracking(trackingData);
    }, 1000);

  },
  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      currentUser: state => state.virtualrow.currentUser,
    }),

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .map(word => word) // Before: asterisker(word, 2, 2)
        .join(" ");
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(pure, 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setService: "autoservice/setService",
      updateB64Final: "autoservice/updateB64Final",
      sendTracking: "virtualrow/handleTracking"
    }),

    async toTurn() {
      if(this.currentUser) this.updateB64Final({ na: this.name, ma: this.currentUser.PrioridadMarcaValor });
      if (this.HorarioOutContinue) this.goToView("UploadFiles");
      else {
        let to = await getOneUseToken();
        let jsonB64 = JSON.stringify({
          ...this.b64final,
          sv: this.service,
          to
        });
        this.$router.push({
          name: "Decoder",
          query: { pr: utf8_to_b64(jsonB64) }
        });
      }
    },
    loadFiles() {
      this.goToView("UploadFiles");
    },
    loadData(type, action) {
      this.setService(type);
      if (!action) {
        return this.toTurn();
      }
      return this.loadFiles();
    }
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
/* Zone Actions Verify */
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}
.form-data {
  margin-top: 40px;
}
.actions-zone {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
}
.min-button {
  min-width: 100px;
}
/* Zone Action Request */
.actions-zone-2 {
  margin-top: 30px;
}
/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.button-origen {
  font-size: 1.3rem;
}
</style>
