import { render, staticRenderFns } from "./BranchValidation.vue?vue&type=template&id=c4733da2&scoped=true&lang=pug&"
import script from "./BranchValidation.vue?vue&type=script&lang=js&"
export * from "./BranchValidation.vue?vue&type=script&lang=js&"
import style0 from "./BranchValidation.vue?vue&type=style&index=0&id=c4733da2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4733da2",
  null
  
)

export default component.exports