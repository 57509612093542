<template lang="pug">
  div(class="position-relative w-100 h-100", style="max-width: 100%")
    div(style="margin: 0 12%;")
      Header()
      .contain(v-if="isSending")
        div(class="text-middle")
          b(class="d-block") Enviando
          span(class="d-block") {{ files.length>1 ? 'Documentos' : 'Documento' }}...
        .mt-5.maxSizingProgress
          Spinner
          //- img.maxSizingProgress.imageContentable(src="@/assets/spinner.gif")
          //- progress-bar(class="maxSizingProgress" type="circle" :ref="progress_sending" :options="options")
      .contain(v-else)
        div(class="text-middle")
          span(class="d-block") {{ files.length>1 ? 'Tus' : 'Tu' }}  
            b(v-show="files.length>1") {{ files.length }} 
            span {{ files.length>1 ? 'documentos' : 'documento' }} 
            b {{ files.length>1 ? 'han' : 'ha' }} 
          b(class="d-block") {{ files.length>1 ? 'sido enviados' : 'sido enviado' }}
          span(class="d-block") exitosamente
        div(class="text-center")
          img(src="./../../assets/like.png" class="sending-image")
        div(class="d-flex justify-content-center mt-2")
          b-button(variant="bluecolmedica" class="text-center mt-5" @click="requestVideoCall()")
            span(class="text-sizing-12") Continuar
            Continue(size="24px")
</template>
<script>
import { mapActions, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
// import { progressUpdated } from "@/utils/calculator";
import { utf8_to_b64 } from "@/utils/decoder";
import { getOneUseToken } from "@/helpers/Meet";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold.vue";
import _get from "lodash/get";
import Spinner from "../components/Spinner";

export default {
  name: "ConfirmUpload",
  components: {
    Header,
    Continue,
    Spinner
  },
  data: () => ({
    isSending: true,
    options: {
      color: "rgb(92, 147, 194)",
      strokeWidth: 8,
      trailColor: "rgb(205, 222, 236)",
      trailWidth: 8,
      fill: "rgb(0, 87, 160)"
    },
    progress_sending: uuidv4(),
    state_progress: null,
    timingController: 3
  }),
  mounted() {
    this.sendFiles();
  },
  computed: {
    ...mapState({
      files: state => state.autoservice.files,
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      env: state => state.env
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .join(" ");
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      updateB64Final: "autoservice/updateB64Final",
      guardarSolicitudColmedica: "autoservice/guardarSolicitudColmedica",
      guardarSolicitudDemo: "autoservice/guardarSolicitudDemo",
      putLog: "log/put"
    }),
    endTiming() {
      if (this.state_progress) this.state_progress.cancel();
      this.isSending = false;
    },
    async requestVideoCall() {
      if (this.HorarioOutContinue) {
        this.goToView("OutOperationsFinish");
      } else {
        let to = await getOneUseToken();
        let jsonB64 = JSON.stringify({
          ...this.b64final,
          sv: this.service,
          to
        });
        this.$router.push({
          name: "Decoder",
          query: { pr: utf8_to_b64(jsonB64) }
        });
      }
    },
    async sendFiles() {
      // this.state_progress = progressUpdated(
      //   this.$refs[this.progress_sending],
      //   this.timingController,
      //   this.endTiming
      // );
      let code;
      try {
        if (this.demoMode) code = await this.guardarSolicitudDemo();
        else code = await this.guardarSolicitudColmedica();
        this.updateB64Final({
          so: code,
          na: this.name
        });
        this.putLog({
          name: "ConfirmUpload. Archivos subidos a la plataforma."
        });
      } catch (error) {
        this.putLog({
          name: "ConfirmUpload. No se subieron los archivos a la plataforma.",
          checked: false,
          message: error
        });
        console.error("Invalid request of documents.", error);
      }
      this.isSending = false;
    }
  },
  destroyed() {
    this.progress_sending = uuidv4();
    this.state_progress = null;
  }
};
</script>

<style scoped>
.contain {
  margin-top: 40px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
  color: #00599d;
}
.maxSizingProgress {
  display: flex;
  justify-content: center;
  max-width: 130px;
  margin: 0px auto;
}
.sending-image {
  margin-top: 20px;
  width: 100px;
}
.imageContentable {
  object-fit: center;
  object-position: center;
}
</style>
