<template lang="pug">

  div
    Header
    div(v-show="explanation")
      
     
      <div style="text-align: center;">
        // <img src="./../../assets/ComingSoonImage.png" style="max-width: 100%; margin 10px; height: auto;" alt="FYI, image alt text is required" />
      </div>
    
      .w--100.text-center 
        .col-12.contain.color-colmedica-gray
          p(style="font-size: 1.3rem; text-align: center;") Bienvenido a Autorizaciones médicas Colmédica
          p(style="font-size: 1.3rem; text-align: center;") Conoce el paso a paso de este canal:
        video(
          id="video"
          src="https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4"
          poster="https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg"
          playsinline
          controls
        )

      .botton-actions
        .d-flex.justify-content-end
          b-button(variant="bluecolmedica" class="text-center ml-4" size="sm" type="button" @click="pauseVideo")
            span(class="text-sizing-12") Continuar
            Continue(size="24px")

      // b-button(variant="bluecolmedica" class="my-2 btn-block align-self-center mx-auto" @click="explanation = !explanation") Next
    div(v-show="!explanation")
      .col-12.contain.color-colmedica-gray
        p(style="font-size: 1.3rem; text-align: center;") Tus autorizaciones médicas
        p(style="font-size: 1.3rem; text-align: center;") a un clic de distancia
        p.mt-5(style="font-size: 1.3rem; text-align: center;") Selecciona un servicio a autorizar
        div()
          b-button.my-2.btn-block.align-self-center.mx-auto.mt-3(
            v-for="service in services"
            :key="service.code_name"
            variant="bluecolmedica"
            @click="setService(service)"
            size="sm"
            class="home-service-button"
          ) {{ service.label }}
      .botton-actions
        .d-flex.justify-content-start
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="setExplanation(true)")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
          

</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import { getOneTimeToken } from "@/helpers/Meet";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";

export default {
  name: "Home",
  components: {
    Header,
    Back,
    Continue
  },
  data: () => ({
    video: undefined
  }),
  mounted() {
    this.startup();
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setExplanation: "autoservice/setExplanation",
      selecteService: "autoservice/selecteService",
      updateB64Final: "autoservice/updateB64Final"
    }),
    async startup() {
      let to = await getOneTimeToken();
      let ma = "cliente";
      let fu = this.$route.path.substring(1);
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE);

      let jsonFinal = { to, tr, su, fu, ma };
      this.updateB64Final(jsonFinal);
      this.video = this.$el.querySelector("#video");
    },
    setService(service) {
      this.selecteService(service);
      this.goToView("Autenticate");
    },
    pauseVideo() {
      this.setExplanation(false);
      this.video.pause();
    }
  },
  computed: {
    ...mapState({
      services: state => state.autoservice.servicesAvailable,
      explanation: state => state.autoservice.explanation,
      env: state => state.env
    })
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
}

#video {
  width: 100%;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.home-service-button {
  font-size: 1.3rem;
}
</style>
