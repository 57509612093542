<template lang="pug">
  div(class="position-relative w-100 h-100", style="max-width: 100%")
    Header
    .contain
      transition(name="fade" mode="out-in")
        div(v-if="!documents.length")
          <br>
          <br>
          p.text-middle.text-center.color-colmedica-gray.text-sizing-2 Por favor adjunta los siguientes documentos:
          ol(class="text-middle" style="margin: 0px 30px")
            li.text-middle.color-colmedica-gray.text-sizing-108 Orden médica por el frente y el revés.
            li.text-middle.color-colmedica-gray.text-sizing-108 Resultados de exámenes relacionados (en caso de tenerlos).
            li.text-middle.color-colmedica-gray.text-sizing-108 Copia de la historia clínica (en caso de tenerla a mano). 

        div(v-else class="documents-zone")
          div(v-for="(data, key) in convertData" :key="'row_' + key" class="snapshot-row")
            div(v-for="(snap, key2) in data" :key="'col_' + key2" class="snapshot-col")
              i.icon.far.fa-times-circle(@click="removeScan(2*key + key2)")
              img(v-if="snap.isImg" :src="snap.file" class="snapshot" @click="showImageInModal(snap)")
              img(v-else src="./../../assets/document_icon.png" class="snapshot" @click="showImageInModal(snap)")
      div(v-show="false")
        input(type="file" accept="image/*" style="display: none" ref="image" @change="fileHandlerImage")
        input(type="file" accept="*/*" style="display: none" ref="file" @change="fileHandlerGeneral")

      b-modal(id="AddDocument" footerClass='p-2 border-top-0' bodyClass="p-1" headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto")
          p(v-if="rotateCompressorError") Lo sentimos, ha habido un error al rotar esta imagen
          div(class="position-relative mt-3" v-if="loadingCompressor" style="max-width: 100px; margin: 15px auto;")
            <Spinner />
          div(class="d-block text-center" v-else)
            img(:src="holdDocument" class="img-fluid max-image-hold" v-if="holdIsImagen")
            .mt-4.mb-4.textMessage(v-else) Sin vista previa del documento
          .position-relative.d-flex
            div(@click="rotateImage" class="mx-auto" v-if="holdIsImagen")
              Rotate(size="40px")
            div(v-if="holdIsImagen" class="my-auto position-absolute" style="right: 0px;")
              span(style="color: rgb(114, 114, 114);") {{ `${ (infoSizeImagen.uncompress/1000000).toFixed(2) }MB -> ${ (infoSizeImagen.compress/1000000).toFixed(2) }MB` }}
          div(class="position-relative mt-3 d-flex")
            b-button(@click="descartDocument()" variant="danger" class="d-block mx-auto my-1 text-sizing-12" size="sm")  Descartar
            b-button(@click="addDocument()" variant="bluecolmedica" class="d-block mx-auto my-1 text-sizing-12" size="sm") Usar

      b-modal(id="ShowImage" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto")
          div(class="d-block text-center")
            img(:src="holdDocument" class="img-fluid max-image-hold" v-if="holdDocument")
            div(v-else) Sin vista previa del documento
          div(class="position-relative mt-3")
            b-button(@click="hiddenImageInModal" variant="bluecolmedica" size="sm" class="text-sizing-12") close

      b-modal(id="ConfirmDelete" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto")
          div(class="d-block text-center")
            div(class="text-sizing-108") Al ir Atrás, perderas las fotos cargadas. Estas seguro?
          div(class="position-relative mt-3")
            b-button(@click="backView()" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") Si, ir Atrás
            b-button(@click="$bvModal.hide('ConfirmDelete')" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") No cancelar

      div(class="zone-button")
        div(class="icon-container" v-if="documents.length<10")
          div(v-if="compressImagen == false")
            div(class="text-center")
              span(class="d-block text-sizing-108 font-weight-bold") Tomar foto o adjuntar documento
            div(class="my-3 p-2 d-flex justify-content-center w-100")
              div(@click="simulateImagen" class="mx-auto w-50")
                Camera(class="m-0 text-center btn-block size-buttons" size="40%") Añadir Imagen
              div(@click="simulateAttach" class="mx-auto w-50")
                Attach(class="m-0 text-center btn-block size-buttons" size="40%") Añadir documento
          div(v-else)
            div(class="mx-auto w-50")
              Cogs(class="m-0 text-center btn-block size-buttons" size="40%")
            div(class="text-center")
              span(class="d-block text-sizing-108 font-weight-bold") Estamos comprimiendo tu imagen
        div(class="icon-container" v-else)
          div(class="text-center")
            span(class="d-block") No puedes adjuntar mas de 10 fotos

        b-button(v-if="documents.length" variant="bluecolmedica" class="text-center" style="margin: 30px auto;display: block;" @click="goToView('ConfirmUpload')") Enviar {{ documents.length }} documento{{ documents.length === 1 ? "" : "s" }}
      .botton-actions
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="documents.length == 0 ? backView() : $bvModal.show('ConfirmDelete')")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
    b-modal(
      id="invalid-mime"
      footerClass='p-2 border-top-0'
      headerClass='p-2 border-bottom-0'
      centered=true
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    )
      h6 No puedes cargar este tipo de archivo. Soportamos imágenes o documentos PDF.
      .buttons.mt-4.mimeErrorCloser
        b-button.ml-2(@click="toogleInvalidModal") Cerrar

    b-modal(
      id="invalid-size"
      footerClass='p-2 border-top-0'
      headerClass='p-2 border-bottom-0'
      centered=true
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    )
      h6 No puedes cargar este archivo. El tamaño máximo del archivo es de 10 MB.
      .buttons.mt-4.sizeErrorCloser
        b-button.ml-2(@click="toogleInvalidModal") Cerrar
</template>
<script>
import { mapActions, mapState } from "vuex";
import chunk from "lodash/chunk";
import Header from "./components/Header";
import Spinner from "../components/Spinner";
// icons
import Camera from "mdi-vue/Camera.vue";
import Attach from "mdi-vue/Attachment.vue";
import Rotate from "mdi-vue/RotateRight";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import Cogs from "mdi-vue/Cogs";

import Compressor from "compressorjs";

export default {
  name: "UploadFiles",
  components: {
    Header,
    Camera,
    Attach,
    Rotate,
    Back,
    Continue,
    Cogs,
    Spinner
  },
  data: () => ({
    holdIsImagen: false,
    holdDocument: null,
    holdDocumentInfo: null,
    compressImagen: false,

    infoSizeImagen: null,

    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],

    loadingCompressor: false,
    rotateCompressorError: false
  }),
  computed: {
    ...mapState({
      documents: state => state.autoservice.files
    }),
    convertData() {
      return chunk(this.documents, 2);
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      removeScan: "autoservice/removeFile",
      addScan: "autoservice/addFile",
      clearDocs: "autoservice/clearDocs",
      putLog: "log/put"
    }),

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.$bvModal.hide("invalid-size");
    },

    // simulate Attach
    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },
    async simulateImagen() {
      this.simulateClick(this.$refs.image);
    },
    simulateClick(elem) {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      elem.dispatchEvent(evt);
    },
    // handlers
    fileHandlerImage() {
      this.fileHandler(this.$refs.image);
    },
    fileHandlerGeneral() {
      this.fileHandler(this.$refs.file);
    },
    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidMime = this.validMimeTypes.reduce(
        (prev, curr) => infoFile.mime.indexOf(curr) === 0 || prev,
        false
      );
      const isValidSize = infoFile.size < 10000000;
      if (!isValidMime) {
        this.putLog({
          name: "UploadFiles. Tipo de archivo inválido.",
          checked: false
        });
        this.$bvModal.show("invalid-mime");
        return;
      }
      if (!isValidSize) {
        this.putLog({
          name: "UploadFiles. Peso de archivo inválido.",
          checked: false
        });
        this.$bvModal.show("invalid-size");
        return;
      }
      this.holdIsImagen = files[0].type.indexOf("image") !== -1;
      this.holdDocumentInfo = infoFile;

      this.compressImagen = true;
      let fileToCompress = {};
      if (this.holdIsImagen) {
        try {
          fileToCompress = await this.compressorJsImage(files[0]);
        } catch (error) {
          fileToCompress = files[0];
        }
        this.holdDocument = await this.readed(fileToCompress);
      } else {
        this.holdDocument = await this.readed(files[0]);
      }
      this.putLog({
        name: "UploadFiles. Archivo comprimido."
      });
      this.infoSizeImagen = {
        uncompress: infoFile.size,
        compress: fileToCompress.size || infoFile.size
      };
      this.compressImagen = false;
      this.$bvModal.show("AddDocument");
      reference.value = "";
    },
    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    compressorJsImage(file) {
      return new Promise((res, rej) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            res(result);
          },
          error(err) {
            rej(err);
          }
        });
      });
    },

    addDocument() {
      this.addScan({
        info: {
          ...this.holdDocumentInfo,
          b64: this.holdDocument.replace(/data:[\w/]+;base64,/g, "")
        },
        file: this.holdDocument,
        isImg: this.holdIsImagen
      });
      this.descartDocument();
    },

    descartDocument() {
      this.$bvModal.hide("AddDocument");
      this.holdDocument = null;
    },

    async rotateImage() {
      this.loadingCompressor = true;
      try {
        const rotateData = await new Promise(res =>
          this.rotate(this.holdDocument, 90, res)
        );
        this.loadingCompressor = false;
        this.holdDocument = rotateData;
      } catch (error) {
        this.loadingCompressor = false;
        this.rotateCompressorError = true;
        console.error("Cant compress image", error);
      }
    },

    rotate(srcBase64, degrees, callback) {
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = function() {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((degrees * Math.PI) / 180);
        ctx.drawImage(image, image.width / -2, image.height / -2);
        callback(canvas.toDataURL());
        this.loadingCompressor = false;
      };
      image.src = srcBase64;
    },

    showImageInModal(snap) {
      this.holdIsImagen = snap.isImg;
      this.holdDocument = snap.file;
      this.$bvModal.show("ShowImage");
    },

    hiddenImageInModal() {
      this.$bvModal.hide("ShowImage");
      this.holdIsImagen = null;
      this.holdDocument = null;
    },

    backView() {
      this.clearDocs();
      this.goToView("VerificationData");
    }
  }
};
</script>

<style scoped>
.textMessage {
  font-size: 1.1rem;
  color: gray;
}
.contain {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
/* Zone Documents */
.documents-zone {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 34%;
}
/* snapshot container */
.snapshot-row {
  position: relative;
  width: auto;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 28px;
}
.snapshot-col {
  width: calc(50% - 10px);
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a2bece;
  border-radius: 5px;
  padding: 5px;
}
.snapshot {
  width: calc(100% - 10px);
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 24px;
  background: white;
  color: #0057a0;
  border-radius: 50%;
  cursor: pointer;
}
/* Button Zone */
.zone-button {
  margin-top: 20px;
}
.size-buttons svg {
  fill: #00599d;
}
.size-buttons {
  margin: 0px auto;
  position: relative;
  display: block;
}
.icon-container {
  border-radius: 10px;
  margin: 10px;
  padding: 10px 0px;
  border: 1px solid #ececec;
  width: auto;
  color: rgb(114, 114, 114);
  background: #ececec;
}

.max-image-hold {
  height: auto;
  width: 100%;
  max-width: 70vw;
  max-height: 70vh;
  object-fit: cover;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}
@media (max-width: 600px) {
  .contain {
    margin-top: 0px;
    height: calc(100% - (58px + 22px));
  }
}
</style>
