<template lang="pug">
div(:style="{ position: 'relative', height: UsableHeight, maxWidth: UsableWidth, margin: '0px auto' }")
  Home(v-if="view == 'Home'")
  TurnChecker(v-if="view == 'TurnChecker'")
  Autenticate(v-if="view == 'Autenticate'")
  UploadFiles(v-if="view == 'UploadFiles'")
  VerificationData(v-if="view == 'VerificationData'")
  ConfirmUpload(v-if="view == 'ConfirmUpload'")
  ErrorAutenticate(v-if="view == 'ErrorAutenticate'")
  ErrorAutenticateVal(v-if="view == 'ErrorAutenticateVal'")
  BranchValidation(v-if="view == 'BranchValidation'")
  OutOperationsFinish(v-if="view == 'OutOperationsFinish'")
</template>
<script>
import { mapState, mapActions } from "vuex";
import EvalMobile from "@/utils/evalmobile";
// views
import Home from "./Home";
import TurnChecker from "./TurnChecker";
import Autenticate from "./Autenticate";
import UploadFiles from "./UploadFiles";
import VerificationData from "./VerificationData";
import ConfirmUpload from "./ConfirmUpload";
import ErrorAutenticate from "./ErrorAutenticate";
import BranchValidation from "./BranchValidation";
import OutOperationsFinish from "./OutOperationsFinish";
import ErrorAutenticateVal from "./ErrorAutenticateVal";

export default {
  name: "autoservice",
  components: {
    Home,
    TurnChecker,
    Autenticate,
    UploadFiles,
    ConfirmUpload,
    VerificationData,
    ErrorAutenticate,
    BranchValidation,
    OutOperationsFinish,
    ErrorAutenticateVal
  },
  mounted(){
    
    // tracking
    let trackingData  = {
      uuid: window.person.id,
      accion: 'Customer - Inicio',
      debmedia_turn_code: '',
      url_origen: window.location.href,
      origen: 'ad',
      mensajes: ''
    }
    
    this.sendTracking(trackingData);
  },
  computed: {
    ...mapState({
      view: state => state.autoservice.view
    }),

    UsableHeight() {
      return window.innerHeight + "px";
    },
    UsableWidth() {
      return EvalMobile.any() ? "100vw" : "500px";
    }
  },
  methods: {
    ...mapActions({
      sendTracking: "virtualrow/handleTracking"
    })
  }
};
</script>
